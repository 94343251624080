<template>
  <el-form-item :label="title" class="card-info">
    <el-table :data="cardList" :span-method="spanMethod" border>
      <el-table-column label="所属商城" min-width="180" v-if="!filterColumn.includes('所属商城')">
        <template slot-scope="{ row, $index }">
          <div class="flex-box">
            <el-select v-if="shopList.length == 0" size="small" :value="row.shop_name" clearable filterable
              @change="handleChangeShopId(row, $index)" @focus="handleShopFocus"
              :disabled="disabledColumn.includes('所属商城')"></el-select>
            <el-select v-else size="small" v-model="row.shop_id" clearable filterable
              @change="handleChangeShopId(row, $index)" @focus="handleShopFocus"
              :disabled="disabledColumn.includes('所属商城')">
              <el-option v-for="(item, index) in shopList" :key="index" :value="item.shop_id" :label="item.shop_name"
                :disabled="filterShop(item.shop_id)" />
            </el-select>
            <el-button class="icon-btn ar-btn" icon="el-icon-circle-plus-outline" circle
              @click="addCardSection(row, $index, 'shop_id')" v-if="!disabledColumn.includes('所属商城')"
              :disabled="!row.shop_id || getNullNum(row, 'shop_id')"></el-button>
            <el-button class="icon-btn ar-btn" icon="el-icon-remove-outline" circle :disabled="cardList.length === 1"
              @click="delCardSection(row, $index, 'shop_id')" v-if="!disabledColumn.includes('所属商城')"></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="卡券名称" min-width="180" v-if="!filterColumn.includes('卡券名称')">
        <template slot-scope="{ row, $index }">
          <div class="flex-box">
            <el-select v-model="row.card_name" filterable clearable size="small"
              @blur="cardNameBlur($event, row, $index)" @focus="getShopCardList(row.shop_id)"
              :disabled="disabledColumn.includes('卡券名称') || !row.shop_id" @change="handleChangeCardId(row, $index)">
              <el-option v-for="(item, index) in shopCardList" :key="index" :label="item.label" :value="item.value"
                :disabled="checkCardName(item)"></el-option>
            </el-select>

            <el-button class="icon-btn ar-btn" icon="el-icon-circle-plus-outline" circle
              @click="addCardSection(row, $index, 'card_id')" v-if="!disabledColumn.includes('卡券名称')" :disabled="!row.shop_id || !row.card_id || getNullNum(row, 'card_id')
    "></el-button>

            <el-button class="icon-btn ar-btn" icon="el-icon-remove-outline" circle :disabled="cardList.length === 1"
              @click="delCardSection(row, $index, 'card_id')" v-if="!disabledColumn.includes('卡券名称')"></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="面值/价位" width="90" v-if="!filterColumn.includes('面值/价位')">
        <template slot-scope="{ row }">
          <el-input-number style="width: 100%" size="small" :controls="false" :min="0.0" :precision="2"
            v-model="row.card_value" :disabled="disabledColumn.includes('面值/价位')" />
        </template>
      </el-table-column>
      <el-table-column label="卡号段" min-width="360" v-if="!filterColumn.includes('卡号段')">
        <template slot-scope="{ row, $index }">
          <div class="flex-box">
            <el-input size="small" ref="cardCodeStart" style="min-width: 150px" v-model.trim="row.card_code_start"
              :disabled="disabledColumn.includes('卡号段') || !row.card_id" @focus="handleSaveCardList"
              @blur="handleCardCouponRange(row, $index, 'range_start')" />
            -
            <el-input size="small" style="min-width: 150px" v-model.trim="row.card_code_end"
              :disabled="disabledColumn.includes('卡号段') || !row.card_id" @focus="handleSaveCardList"
              @blur="handleCardCouponRange(row, $index, 'range_end')" />
            <el-button class="icon-btn ar-btn" icon="el-icon-circle-plus-outline" circle
              @click="addCardSection(row, $index, 'card_code')" v-if="!disabledColumn.includes('卡号段')" :disabled="!row.card_id ||
    !row.card_code_start ||
    !row.card_code_end ||
    getNullNum(row, 'card_range')
    "></el-button>

            <el-button class="icon-btn ar-btn" :disabled="cardList.length === 1" icon="el-icon-remove-outline" circle
              @click="delCardSection(row, $index, 'card_code')" v-if="!disabledColumn.includes('卡号段')"></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="折扣率(%)" width="100" v-if="!filterColumn.includes('折扣率(%)')">
        <template slot-scope="{ row, $index }">
          <el-input-number size="small" style="width: 100%" v-model.number="row.discount_ratio" :controls="false"
            :min="0.0" :max="100.0" :precision="2" @blur="handleDiscountBlur('discount_ratio', row, $index)"
            :disabled="true"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column width="100" label="数量" v-if="!filterColumn.includes('数量')">
        <template slot-scope="{ row, $index }">
          <el-input-number style="width: 100%" :controls="false" :min="0" :precision="0" size="small"
            v-model.number="row.merge_card_num" @input="(item, val) => {
    if (val && val.toString().includes('-')) {
      item.merge_card_num = 0;
    }
  }
    " @focus="handleSaveCardList" @blur="handleTableChange(row, $index, 'num')" :disabled="row.disabledCardNum ||
    disabledColumn.includes('数量') ||
    checkDisabledNum(row, $index)
    " />
        </template>
      </el-table-column>
      <el-table-column label="优惠金额" width="100" v-if="!filterColumn.includes('优惠金额')">
        <template slot-scope="{ row, $index }">
          <el-input-number size="small" style="width: 100%" v-model.number="row.merge_discount_value" :controls="false"
            :min="0.0" :precision="2" @blur="handleDiscountBlur('discount_value', row, $index)"
            :disabled="true"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="优惠后金额" width="100" v-if="!filterColumn.includes('优惠后金额')">
        <template slot-scope="{ row, $index }">
          <el-input-number size="small" style="width: 100%" v-model="row.merge_after_discount_price" :controls="false"
            :min="0.0" :precision="2" @blur="handleDiscountBlur('after_discount_price', row, $index)"
            :disabled="true"></el-input-number>
        </template>
      </el-table-column>
      <!-- <el-table-column label="统一折扣率设置" width="150" v-if="!filterColumn.includes('统一折扣率设置')">
        <template slot-scope="{ row, $index }">
          <div class="td-switch-box">
            <el-switch v-model="row.unify_discount_rate_status" @change="changeDiscountRatio(row, $index)" :disabled="disabledColumn.includes('统一折扣率设置') || !row.shop_id
              "></el-switch>
            <el-input-number size="small" style="width: 80px" v-model="row.unify_discount_rate" :controls="false"
              :min="0.0" :max="100.0" :precision="2" :disabled="!row.unify_discount_rate_status ||
                disabledColumn.includes('统一折扣率设置') ||
                !row.shop_id
                " @change="changeDiscountRatio(row, $index)" />
          </div>
        </template>
      </el-table-column> -->
    </el-table>
  </el-form-item>
</template>

<script>
import * as API_saleCard from "@/api/saleCard";
import { createDefaultItem } from "@/views/tools/sale-card-open/utils/create";

export default {
  name: "card-list",
  props: {
    orgin: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "卡券信息",
    },
    disabled: {
      type: String | Boolean | Array,
      default: () => {
        return ["面值/价位"];
      },
    },
    filterColumn: {
      default: () => {
        return []; // "统一折扣率设置"
      },
    },
    shopData: {
      default: () => [],
    },
    cardData: {
      default: () => [],
    },
    appType: {
      type: String | Number,
      default: "",
    },
    tradeSn: {
      type: String | Number,
      default: "",
    },
  },
  data () {
    return {
      shopList: [],
      cardList: [],
      spanArr: [],
      shopCardList: [],
      lastShop: null,
      disabledColumn: [],
      hisCardStr: [],
    };
  },
  watch: {
    disabled (v) {
      if (v === true) {
        this.disabledColumn = [
          "所属商城",
          "卡券名称",
          "面值/价位",
          "卡号段",
          "折扣率(%)",
          "数量",
          "优惠金额",
          "优惠后金额",
          "统一折扣率设置",
        ];
      } else {
        this.disabledColumn = v;
      }
    },
    cardData (v) {
      this.init();
    },
    shopData (v) {
      this.shopList = v || [];
    },
    appType (v) {
      if (this.shopData.length === 0 && v) {
        this.getShopList(v);
      }
    },
  },
  created () {
    if (this.disabled === true) {
      if (this.shopData.length === 0) this.getShopList();
      this.disabledColumn = [
        "所属商城",
        "卡券名称",
        "面值/价位",
        "卡号段",
        "折扣率(%)",
        "数量",
        "优惠金额",
        "优惠后金额",
        "统一折扣率设置",
      ];
    } else {
      this.disabledColumn = this.disabled;
    }
    this.getShopList(this.appType);
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      console.log("init", this.cardData);
      if (this.cardData.length === 0) {
        this.cardList = [createDefaultItem()];
      } else {
        this.cardList = [...this.cardData].map((item) => {
          return {
            ...createDefaultItem(),
            ...item,
          };
        });
        this.emitChange();
      }
    },
    gbl (n) {
      return Boolean(n);
    },
    clear () {
      this.cardList.length = 0;
      this.cardList = [createDefaultItem()];
      this.emitChange();
    },
    reset () {
      this.cardList = [...this.cardData];
      this.emitChange();
    },
    getCardData () {
      return this.groupArrayByKey(this.cardList, "shop_id");
    },
    // 获取商城的卡券列表
    getShopCardList (val) {
      if (val === this.lastShop) return;
      if (this.tradeSn) {
        API_saleCard.getShopCardList3({
          shop_id: val,
          trade_sn: this.tradeSn,
        }).then((res) => {
          this.lastShop = val;
          this.shopCardList = res.map((item) => {
            return {
              value: item.card_id,
              label: item.card_name,
            };
          });
        });
      } else {
        API_saleCard.getShopCardList(val, this.appType).then((res) => {
          this.lastShop = val;
          this.shopCardList = res.map((item) => {
            return {
              value: item.id,
              label: item.card_name,
            };
          });
        });
      }
    },
    handleSaveCardList () {
      let list = this.cardList.map((item) => item);
      this.hisCardStr = JSON.stringify(list);
    },
    checkList (data) {
      let _objData = this.groupArrayByKey(data, "shop_id");
      for (const key in _objData) {
        let _data = _objData[key];
        this._checkList(_data);
      }
    },
    _checkList (data, key = "shop_id", index = 0) {
      let _item;
      for (let i = 0; i < data.length; i++) {
        if (!data[i].shop_id) {
          data[i].rowsapn = [1, 1, 1];
        }
        // if (
        //   i === 0 ||
        //   (data[i][key] === data[i - 1][key] && data[i][key] !== "")
        // ) {
        let obj = data.find((d) => d[key] === data[i][key]);
        let _list = data.filter((d) => d[key] === data[i][key]) || [];
        obj.rowsapn[index] = _list.length;
        // data[i].rowsapn[index] = 0;
        if (i === 0) obj.rowsapn[index] = 1;
        else if (i > 0 && data[i][key] == data[i - 1][key]) {
          data[i].rowsapn[index] = 0;
        }
        if (_list.length && _item != obj && key === "shop_id") {
          _item = obj;
          this._checkList(_list, "card_id", 1);
        }
        if (_list.length && _item != obj && key === "card_id") {
          _list.forEach((item) => {
            item.total_value = +item.card_value * +item.card_num;
          });
          obj["merge_card_num"] = _list
            .map((item) => +item.card_num)
            .reduce((a, b) => a + b, 0);
          obj["merge_discount_value"] = _list
            .map((item) => {
              return this.roundNum(
                item.total_value * (1 - item.discount_ratio / 100)
              );
            })
            .reduce((a, b) => a + b, 0);

          obj["merge_after_discount_price"] = _list
            .map((item) => {
              return this.roundNum(
                item.total_value * (item.discount_ratio / 100)
              );
            })
            .reduce((a, b) => a + b, 0);
        }
        // } else {
        //   data[i].rowsapn[index] = 1;
        // }
        // }
      }
    },
    spanMethod ({ row, column, rowIndex, columnIndex }) {
      if (["所属商城", "统一折扣率设置"].includes(column.label)) {
        return {
          rowspan: row.rowsapn[0],
          colspan: 1,
        };
      }
      if (
        ["卡券名称", "面值/价位", "数量", "优惠金额", "优惠后金额"].includes(
          column.label
        )
      ) {
        return {
          rowspan: row.rowsapn[1],
          colspan: 1,
        };
      }
    },
    handleDiscountBlur (type, row, index) {
      // console.log(row);
      if (!this.appType) {
        return this.$message.error("请先选择应用类型");
      }
      if (!row.shop_id) {
        return this.$message.error("请先选择商城");
      }
      if (!row.card_code_end || !row.card_code_start || !row.card_value) {
        return this.$message.error("请先导入或输入卡号段");
      }
      let total = 0;
      let list1 = this.groupArrayByKey(this.cardList, "shop_id")[row.shop_id];
      let list2 = this.groupArrayByKey(list1, "card_id")[row.card_id];
      for (let i = 0; i < list2.length; i++) {
        let item = list2[i];
        total += +item.card_value * +item.card_num;
      }
      if (type === "discount_value") {
        if (row.merge_discount_value > total) {
          row.merge_discount_value = total;
          this.$message.error("优惠金额最大只能到面值*数量所对应的金额");
        }
        for (let i = 0; i < list2.length; i++) {
          let item = list2[i];
          item.discount_ratio = Math.min(
            100,
            Math.max(0, (1 - row.merge_discount_value / total) * 100)
          );
        }
        row.unify_discount_rate = row.discount_ratio;
      }
      if (type == "after_discount_price") {
        if (row.merge_after_discount_price > total) {
          row.merge_after_discount_price = total;
          this.$message.error("优惠后金额最大只能到面值*数量所对应的金额");
        }
        for (let i = 0; i < list2.length; i++) {
          let item = list2[i];
          item.discount_ratio = Math.min(
            100,
            Math.max(0, (row.merge_after_discount_price / total) * 100)
          );
        }
        row.unify_discount_rate = row.discount_ratio;
      }

      this.emitChange();
    },
    emitChange () {
      this.checkList(this.cardList);
      const { totalPrice, discountPrice } = this.totalPriceAndDiscountPrice();
      this.$emit("change", {
        cardList: this.cardList,
        totalPrice,
        discountPrice,
      });
    },
    roundNum (num, decimal = 10) {
      return Math.round(num * 10 ** decimal) / 10 ** decimal;
    },
    //计算合计总金额和优惠后总金额
    totalPriceAndDiscountPrice () {
      let totalPrice = 0,
        discountPrice = 0;
      this.cardList.forEach((item) => {
        totalPrice += this.roundNum(+item.card_num * +item.card_value);
        discountPrice += this.roundNum(
          +item.card_num * +item.card_value * (item.discount_ratio / 100)
        );
      });
      totalPrice = this.roundNum(totalPrice);
      discountPrice = this.roundNum(discountPrice);
      return {
        totalPrice,
        discountPrice,
      };
    },
    addCardSection (row, index, type) {
      let item = createDefaultItem();
      let list = [...this.cardList];
      let tab = 0;
      switch (type) {
        case "shop_id":
          tab = list.length;
          break;
        case "card_id":
          item.shop_id = row.shop_id;
          item.shop_name = row.shop_name;
          if (row.unify_discount_rate_status) {
            item.discount_ratio = row.unify_discount_rate;
            item.unify_discount_rate_status = row.unify_discount_rate_status;
            item.unify_discount_rate = row.unify_discount_rate;
          }

          for (let i = index; i < list.length; i++) {
            if (list[i].shop_id != row.shop_id) {
              tab = i;
              break;
            } else tab = list.length;
          }
          break;
        case "card_code":
          item.shop_id = row.shop_id;
          item.shop_name = row.shop_name;
          item.card_id = row.card_id;
          item.card_name = row.card_name;

          item.discount_ratio = row.discount_ratio;
          item.unify_discount_rate_status = row.unify_discount_rate_status;
          item.unify_discount_rate = row.unify_discount_rate;

          for (let i = index; i < list.length; i++) {
            if (
              list[i].shop_id != row.shop_id ||
              (list[i].shop_id == row.shop_id && list[i].card_id != row.card_id)
            ) {
              tab = i;
              break;
            } else tab = list.length;
          }
        default:
          break;
      }
      this.cardList.splice(tab, 0, item);
      this.emitChange();
    },
    delCardSection (row, index, type) {
      if (row[type] === "") {
        this.cardList.splice(index, 1);
        this.emitChange();
        return;
      }
      switch (type) {
        case "shop_id":
          this.cardList = [].filter.call(
            this.cardList,
            (item) => item.shop_id !== row.shop_id
          );
          break;
        case "card_id":
          let _clist = this.cardList.filter(
            (item) => item.shop_id == row.shop_id
          );
          if (_clist.length > 1) {
            let _clist2 = this.cardList.filter(
              (item) => item.card_id == row.card_id
            );
            if (_clist2.length > 1) {
              this.$set(this.cardList, index, {
                ...createDefaultItem(),
                shop_id: row.shop_id,
                shop_name: row.shop_name,
              });
            }
            this.cardList = [].filter.call(
              this.cardList,
              (item) =>
                (item.shop_id === row.shop_id &&
                  item.card_id !== row.card_id) ||
                item.shop_id !== row.shop_id
            );
          } else {
            this.$set(this.cardList, index, {
              ...createDefaultItem(),
              shop_id: row.shop_id,
              shop_name: row.shop_name,
            });
          }
          break;
        case "card_code":
          this.cardList.splice(index, 1);
        default:
          break;
      }
      if (this.cardList.length === 0) this.addCardSection();

      this.emitChange();
    },
    // 卡券名称blur事件
    cardNameBlur ($event, item, index) { },
    // 编辑卡券信息
    handleTableChange (row, index, type) {
      this.handleCardCouponRange(row, index, type);
      // let _cards = this.groupArrayByKey(this.cardList, "shop_id");
      // let _list = _cards[row.shop_id];
      // this.$emit("range", {
      //   type,
      //   item: row,
      //   index,
      //   list: _list,
      //   cards: _cards,
      //   cardList: this.cardList,
      // });
    },
    checkDisabledNum (row, index) {
      // console.log(row);
      if (
        !row.shop_id ||
        !row.card_id ||
        !row.card_code_start ||
        !row.card_code_end
      ) {
        return true;
      }

      let list1 = this.groupArrayByKey(this.cardList, "shop_id")[row.shop_id];

      for (let i = 0; i < list1.length; i++) {
        let item = list1[i];
        if (!item.card_code_start || !item.card_code_end) {
          return true;
        }
      }
      return false;
    },
    backtrack (opt) {
      let list = JSON.parse(this.hisCardStr);
      this.cardList = list;
    },
    // 编辑卡号段
    handleCardCouponRange (row, $index, type) {
      let card_code_start = row.card_code_start + "";
      let card_code_end = row.card_code_end + "";
      if (!card_code_start || !card_code_end) {
        this.$message.error("卡号区间的起始、结束输入框为必填项");
        return false;
      }
      // console.log(this.cardList);
      let _cards = this.groupArrayByKey(this.cardList, "shop_id");
      if (!this.orgin) _cards = JSON.parse(JSON.stringify(_cards));

      let _list = _cards[row.shop_id].map((item) => {
        item.unify_discount_rate_status = +item.unify_discount_rate_status;
        item.unify_discount_rate = +item.unify_discount_rate;
        return item;
      });
      let index = _list.findIndex(
        (item) =>
          item.shop_id == row.shop_id &&
          item.card_id == row.card_id &&
          item.card_code_start == card_code_start &&
          item.card_code_end == card_code_end
      );
      if (_list.find((item) => !item.card_code_start || !item.card_code_end)) {
        this.$message.error("请完善当前已创建的卡券信息");
        return false;
      }
      let reg = /^(?:\w+)?\d+$/;
      if (!reg.test(card_code_start) || !reg.test(card_code_end)) {
        console.log(
          card_code_start,
          card_code_end,
          reg.test(card_code_start),
          reg.test(card_code_end)
        );
        this.$message.error("卡号区间的格式有误");
        this.backtrack();
        return;
      }
      let csm = card_code_start.match(/^[a-zA-Z]+/);
      let esm = card_code_end.match(/^[a-zA-Z]+/);
      let head_start = csm ? csm[0] : "";
      let head_end = esm ? esm[0] : "";

      let card_code_num_start = head_start
        ? card_code_start.split(head_start)[1]
        : card_code_start;

      let card_code_num_end = head_end
        ? card_code_end.split(head_end)[1]
        : card_code_end;

      if (head_start != head_end) {
        this.$message.error("卡号区间的前缀有误");
        this.backtrack();
        return;
      }

      if (+card_code_num_start > +card_code_num_end) {
        this.$message.error("卡号区间起始不能大于结束");
        this.backtrack();
        return;
      }

      // console.log(selectItem)
      this.$emit("range", {
        type,
        item: row,
        index,
        list: _list,
        cards: _cards,
        cardList: this.cardList,
      });
    },
    handleChangeCardId (row, index) {
      if (row.card_name == "") {
        this.$set(this.cardList, index, {
          ...createDefaultItem(),
          shop_id: row.shop_id,
          shop_name: row.shop_name,
        });
        this.emitChange();
        return;
      }
      row.card_id = row.card_name;
      row.card_name = this.shopCardList.find(
        (item) => item.value === row.card_id
      ).label;
      if (row.rowsapn[1] > 1) {
        for (let i = index + 1; i < this.cardList.length; i++) {
          const item = this.cardList[i];
          if (item.rowsapn[1] === 0) {
            this.cardList.splice(i, 1);
            i -= 1;
          } else break;
        }
      }
      this.$set(this.cardList, index, {
        ...createDefaultItem(),
        shop_id: row.shop_id,
        shop_name: row.shop_name,
        card_id: row.card_id,
        card_name: row.card_name,
      });
      this.emitChange();
      this.$emit("changeCardId", row, index);
    },
    handleChangeShopId (row, index) {
      this.lastShop = null
      if (row.shop_id == "") {
        this.$set(this.cardList, index, {
          ...createDefaultItem(),
        });
        this.emitChange();
        return;
      }
      row.shop_name = this.shopList.find(
        (item) => item.shop_id === row.shop_id
      ).shop_name;
      if (row.rowsapn[0] > 1) {
        for (let i = index + 1; i < this.cardList.length; i++) {
          const item = this.cardList[i];
          if (item.rowsapn[0] === 0) {
            this.cardList.splice(i, 1);
            i -= 1;
          } else break;
        }
      }

      this.$set(this.cardList, index, {
        ...createDefaultItem(),
        shop_id: row.shop_id,
        shop_name: row.shop_name,
      });
      this.emitChange();
    },
    changeDiscountRatio (row, index) {
      row.unify_discount_rate = Math.max(
        0,
        Math.min(100, row.unify_discount_rate)
      );
      let list = this.groupArrayByKey(this.cardList, "shop_id")[row.shop_id];
      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        item.unify_discount_rate = row.unify_discount_rate;
        item.unify_discount_rate_status = row.unify_discount_rate_status;
        if (item.unify_discount_rate_status) {
          item.discount_ratio = row.unify_discount_rate;
        }
      }
      this.emitChange();
    },
    checkCardName (obj) {
      return this.cardList.some((item) => item.card_id == obj.value);
    },
    handleShopFocus () {
      if (!this.appType) {
        return this.$message.error("请先选择应用类型");
      }
      this.getShopList(this.appType);
    },
    getShopList () {
      this.shopList = this.shopData;
      // API_saleCard.getShopList({ mall_type: 2 }).then((res) => {
      //   this.shopList = res;
      // });
    },
    isEmpty (value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value == "object" && Object.keys(value).length == 0) ||
        (typeof value == "string" && value.trim().length == 0)
      );
    },
    groupArrayByKey (arr = [], key) {
      return arr.reduce(
        (t, v) => (!t[v[key]] && (t[v[key]] = []), t[v[key]].push(v), t),
        {}
      );
    },
    getShopCardInfoList (cardList = this.cardList) {
      let shop_card_info_list = [];
      let _list = cardList.filter(
        (item) =>
          item.shop_id &&
          item.card_id &&
          item.card_code_start &&
          item.card_code_end
      );
      let _map = this.groupArrayByKey(_list, "shop_id");
      if (this.isEmpty(_map)) return [];
      for (const [key, value] of Object.entries(_map)) {
        const {
          shop_name,
          app_type_shop_type_flag,
          unify_discount_rate_status,
          unify_discount_rate,
        } = value[0];
        let _value = [...value];
        _value.forEach((item) => {
          item.unify_discount_rate_status = +item.unify_discount_rate_status;
          item.unify_discount_rate = +item.unify_discount_rate;
        });
        shop_card_info_list.push({
          shop_id: key,
          shop_name,
          app_type_shop_type_flag,
          card_range_list: _value,
          unify_discount_rate_status: +unify_discount_rate_status,
          unify_discount_rate: +unify_discount_rate,
        });
      }
      return shop_card_info_list;
    },
    getNullNum (row, type) {
      if (type == "shop_id") {
        let list = this.groupArrayByKey(this.cardList, type)[""] || [];
        return !!list.length;
      }
      if (type == "card_id") {
        let list =
          this.groupArrayByKey(this.cardList, "shop_id")[row.shop_id] || [];
        let list2 = this.groupArrayByKey(list, "card_id")[""] || [];
        return !!list2.length;
      }
      if (type == "card_range") {
        let list =
          this.groupArrayByKey(this.cardList, "shop_id")[row.shop_id] || [];
        let list2 = this.groupArrayByKey(list, "card_id")[row.card_id] || [];
        let list3 = this.groupArrayByKey(list2, "card_code_start")[""] || [];
        let list4 = this.groupArrayByKey(list2, "card_code_end")[""] || [];
        return !!list3.length || !!list4.length;
      }
    },
    filterShop (id) {
      let map = this.groupArrayByKey(this.cardList, "shop_id");
      return !!map[id];
    },
  },
};
</script>

<style lang="scss" scoped>
.card-info {
  /deep/ .el-form-item__label {
    display: inline-block !important;
    float: none !important;
  }

  /deep/ .el-form-item__content {
    margin-left: 55px !important;
  }

  /deep/ .el-form-item__content {
    table {
      border: 1px solid #e6ecf7;

      tr>th {
        text-align: center;
        background-color: #e6ecf7;
        padding: 0;
      }

      tr>td {
        padding: 7px 3px;
        text-align: center;

        input {
          width: 100%;
        }

        .el-input__inner {
          padding: 0 5px;
        }
      }

      tr {

        .card-name,
        .card-section {
          min-width: auto !important;
        }

        .card-name {
          .warn-span {
            position: absolute;
            top: 80px;
            left: 100px;
            font-size: 14px;
            color: red;
          }
        }
      }
    }
  }
}

.td-switch-box {
  display: flex;
  align-items: center;

  .el-switch {
    margin-right: 5px;
  }
}

.card-section {
  &>div {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    /deep/ .el-input {
      width: 95px;

      &:first-of-type {
        margin-right: 3px !important;
      }

      &:last-of-type {
        margin-left: 3px !important;
      }
    }
  }
}

.flex-box {
  display: flex;
  align-items: center;

  /deep/ .el-select {
    flex: 1;
  }
}

.ar-btn {
  margin-left: 2px;
  border: 0;
  padding: 0;
  font-size: 18px !important;
}
</style>
